@import '../../../theme/common.scss';

$componentName: "blog";
$root: #{$classPrefix}#{$componentName}-root;
$content: #{$classPrefix}#{$componentName}-content;
$links: #{$classPrefix}#{$componentName}-links;
$latestPosts: #{$classPrefix}#{$componentName}-latest-posts;
$post: #{$classPrefix}#{$componentName}-latest-post;

.#{$root}{
    .#{$sectionTitleContainer}{
        position: relative;
        width: 100%;
        text-align: center;
        margin-top: 120px;
    }

    section {
        margin: 0 40px;
    }
    .#{$sectionTitle}{
        font-size: $font-size-large;
        color: $color-dark2;
        background-color: $color-light3;
        font-style: italic;
        padding: 8px;
        text-align: center;
        white-space: pre-wrap;
        display: inline-block;
        zoom: 1;
        max-width: 100%;
        position: relative;
        margin-bottom: 40px;
    }
    margin-top: 120px;

    .#{$content}{
        font-size: $font-size-xsmall;
    }
    .#{$links}{
        margin-top: 20px;
        font-size: $font-size-xsmall;
        display: flex;
        gap: 20px;
    }

    .#{$latestPosts}{
        margin-bottom: 40px;
        h2 {
            margin-top: 30px;
        }

        .#{$post} {
            border-radius: 4px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
            padding: 20px;
            background: $color-light2;
            margin-top: 20px;
            a {
                font-weight: $font-weight-semi-bold;
                color: $color-dark2 !important;
            }
        }
    }
}

@media (max-width:  850px){
    .#{$root}{
    }

}