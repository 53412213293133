@import '../../theme/common.scss';

$componentName: "top-projects";
$root: #{$classPrefix}#{$componentName}-root;
$project: #{$classPrefix}#{$componentName}-project;
$projectImage: #{$classPrefix}#{$componentName}-project-image;
$projectName: #{$classPrefix}#{$componentName}-project-name;
$projectDescrition: #{$classPrefix}#{$componentName}-project-description;
$projectData: #{$classPrefix}#{$componentName}-project-data;

.#{$root}{
    display: flex;
    justify-content: center;
    margin: 60px 0;
    position: relative;

    .#{$project}{ 
        display: flex;
        flex-direction: column;
        background: $color-light4;
        width: calc(100% / 3 - 20px);
        max-width: 250px;
        margin: 0 10px;
        padding: 20px;
        align-items: center;
        z-index: 2;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        overflow: hidden;
    }

    .#{$projectName}{ 
        margin: 10px 0;
    }
    .#{$projectDescrition}{ 
        min-height: 250px;
        text-align:justify;
    }
    .#{$projectImage}{ 
        width: 150px;
        height: 150px;
        padding: 10px;
    }
} /* eof section */

@media (max-width:  850px){
    .#{$root}{
        flex-direction: column;
        gap: 20px;
        margin: 0;
    
        .#{$project}{ 
            width: 88%;
            margin: 0;
            max-width: 88%;
            flex-direction: column;
        }

        .#{$project}:nth-child(1){ 
            background: url(../../assets/projects/fear-the-lights-out.png) no-repeat;
            background-size: 60px 60px;
            background-position: right top;
            background-color: $color-light;
        }
        .#{$project}:nth-child(2){ 
            background: url(../../assets/projects/bang-squash.png) no-repeat;
            background-size: 60px 60px;
            background-position: right top;
            background-color: $color-light;
        }
        .#{$project}:nth-child(3){ 
            background: url(../../assets/projects/maze-of-the-mummy.png) no-repeat;
            background-size: 60px 60px;
            background-position: right top;
            background-color: $color-light;
        }

        .#{$projectImage}{ 
            display: none;
        }

        .#{$projectData}{ 
            display: flex;
            flex-direction: column;
        }

        .#{$projectDescrition}{ 
            min-height: unset;
            margin-bottom: 8px;
        }
    }
}