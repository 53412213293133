@import '../../theme/common.scss';

$componentName: "footer";
$root: #{$classPrefix}#{$componentName}-root;
$info: #{$classPrefix}#{$componentName}-info;
$technology: #{$classPrefix}#{$componentName}-technology;

.#{$root}{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border-top: 2px solid $color-dark;
}

.#{$info}{
    background: $color-light;
    color: $color-dark;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: $font-size-xxsmall;
   
} // eof footer info


.#{$technology}{
    font-size: $font-size-xxxxsmall;
}

@media (max-width:  850px){
    .#{$root}{
        margin-top: 20px;
        height: auto;
        padding: 10px;
        width: calc(100% - 20px);
    }
    .#{$info}{
        height: auto;
        font-size: 14px;
        display: flex;
        flex-direction: column;

        span {
            display: block;
        }

    }

    .#{$technology}{
        margin-top: 10px;
        text-align: center;
    }
}