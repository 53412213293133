/* LIGHT */

@import './typography.scss';

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: block;
    font-weight: $font-weight-light;
    src: local('Gilroy Light'), local('Gilroy-Light'),
      url('./fonts/Gilroy-Light.eot'),
      url('./fonts/Gilroy-Light.woff2') format('woff2'),
      url('./fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Light.woff') format('woff'),
      url('./fonts/Gilroy-Light.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-display: block;
    font-weight: $font-weight-light;
    src: local('Gilroy Light Italic'), local('Gilroy-Light-Italic'),
      url('./fonts/Gilroy-Light-Italic.eot'),
      url('./fonts/Gilroy-Light-Italic.woff2') format('woff2'),
      url('./fonts/Gilroy-Light-Italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Light-Italic.woff') format('woff'),
      url('./fonts/Gilroy-Light-Italic.ttf') format('truetype');
  }
  
  /* REGULAR */
  
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: block;
    font-weight: $font-weight-normal;
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
      url('./fonts/Gilroy-Regular.eot'),
      url('./fonts/Gilroy-Regular.woff2') format('woff2'),
      url('./fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Regular.woff') format('woff'),
      url('./fonts/Gilroy-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-display: block;
    font-weight: $font-weight-normal;
    src: local('Gilroy Regular Italic'), local('Gilroy-Regular-Italic'),
      url('./fonts/Gilroy-Regular-Italic.eot'),
      url('./fonts/Gilroy-Regular-Italic.woff2') format('woff2'),
      url('./fonts/Gilroy-Regular-Italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Regular-Italic.woff') format('woff'),
      url('./fonts/Gilroy-Regular-Italic.ttf') format('truetype');
  }
  
  /* MEDIUM */
  
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: block;
    font-weight: $font-weight-medium;
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
      url('./fonts/Gilroy-Medium.eot'),
      url('./fonts/Gilroy-Medium.woff2') format('woff2'),
      url('./fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Medium.woff') format('woff'),
      url('./fonts/Gilroy-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-display: block;
    font-weight: $font-weight-medium;
    src: local('Gilroy Medium Italic'), local('Gilroy-Medium-Italic'),
      url('./fonts/Gilroy-Medium-Italic.eot'),
      url('./fonts/Gilroy-Medium-Italic.woff2') format('woff2'),
      url('./fonts/Gilroy-Medium-Italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Medium-Italic.woff') format('woff'),
      url('./fonts/Gilroy-Medium-Italic.ttf') format('truetype');
  }
  
  /* SEMIBOLD */
  
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: block;
    font-weight: $font-weight-semi-bold;
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
      url('./fonts/Gilroy-Semibold.eot'),
      url('./fonts/Gilroy-Semibold.woff2') format('woff2'),
      url('./fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Semibold.woff') format('woff'),
      url('./fonts/Gilroy-Semibold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-display: block;
    font-weight: $font-weight-semi-bold;
    src: local('Gilroy Semibold Italic'), local('Gilroy-Semibold-Italic'),
      url('./fonts/Gilroy-Semibold-Italic.eot'),
      url('./fonts/Gilroy-Semibold-Italic.woff2') format('woff2'),
      url('./fonts/Gilroy-Semibold-Italic.eot?#iefix')
        format('embedded-opentype'),
      url('./fonts/Gilroy-Semibold-Italic.woff') format('woff'),
      url('./fonts/Gilroy-Semibold-Italic.ttf') format('truetype');
  }
  
  /* BOLD */
  
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: block;
    font-weight: $font-weight-bold;
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
      url('./fonts/Gilroy-Bold.eot'),
      url('./fonts/Gilroy-Bold.woff2') format('woff2'),
      url('./fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Bold.woff') format('woff'),
      url('./fonts/Gilroy-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-display: block;
    font-weight: $font-weight-bold;
    src: local('Gilroy Bold Italic'), local('Gilroy-Bold-Italic'),
      url('./fonts/Gilroy-Bold-Italic.eot'),
      url('./fonts/Gilroy-Bold-Italic.woff2') format('woff2'),
      url('./fonts/Gilroy-Bold-Italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Bold-Italic.woff') format('woff'),
      url('./fonts/Gilroy-Bold-Italic.ttf') format('truetype');
  }
  