@import './theme/common.scss';

body {
    color: $default-text-color;
    font-family: 'Gilroy', 'Arial', sans-serif;
    font-size: 100%;

    // disable blue highlight on touch devices when the item is clicked
    a {
      -webkit-tap-highlight-color: transparent;
    }

    h1, h2, h3, h4, p{
      padding: 0;
      margin: 0;
    }
  }
  
.#{$section}{
  margin: 0;
  padding: 40px 10%;
  position: relative;
  max-width: 100%;
  overflow: hidden;

  .#{$sectionTitleContainer}{
      position: relative;
      width: 100%;
      text-align: center;
  }


  .#{$sectionTitle}{
      font-size: $font-size-large;
      color: $color-dark2;
      background-color: $color-light3;
      font-style: italic;
      padding: 8px;
      text-align: center;
      white-space: pre-wrap;
      display: inline-block;
      zoom: 1;
      max-width: 100%;
      position: relative;
      margin-bottom: 40px;
  }

  .#{$sectionTitle}::before {
      content: " ";
      position: absolute;
      top: 50%;
      height: 2px;
      width: 80px;
      margin: 0 10px;
      display: inline-block;
      zoom: 1;
      background: $color-dark2;
      right: 100%;
  }

  .#{$sectionTitle}::after {
      content: " ";
      position: absolute;
      top: 50%;
      height: 2px;
      width: 80px;
      margin: 0 20px;
      display: inline-block;
      zoom: 1;
      background: $color-dark2;
  }

  .#{$sectionContent}{
      color: $color-dark2;
      font-size: $font-size-xsmall;
      text-align: justify;
      display: flex;
      flex-direction: column;
      gap: 20px;

      ul li {
          margin-bottom: 20px;
      }

      a {
          color: $color-dark2;
      }
  }
} /* eof section */


@media (max-width:  850px){
  .#{$section}{
      padding: 40px 6%;

      .#{$sectionTitle}{
          font-size: $font-size-small;
      }

      .#{$sectionTitle}::before {
          width: 40px;
          margin: 0 10px;
      }
  
      .#{$sectionTitle}::after {
          width: 40px;
          margin: 0 20px;
      }

      .#{$sectionContent}{
          font-size: $font-size-xxsmall;
      }
  }
}