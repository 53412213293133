@import '../../../theme/common.scss';

$componentName: "privacy";
$root: #{$classPrefix}#{$componentName}-root;
$footer: #{$classPrefix}#{$componentName}-footer;
$data: #{$classPrefix}#{$componentName}-data;

.#{$root}{
    .#{$sectionTitleContainer}{
        position: relative;
        width: 100%;
        text-align: center;
        margin-top: 120px;
    }
    .#{$sectionTitle}{
        font-size: $font-size-large;
        color: $color-dark2;
        background-color: $color-light3;
        font-style: italic;
        padding: 8px;
        text-align: center;
        white-space: pre-wrap;
        display: inline-block;
        zoom: 1;
        max-width: 100%;
        position: relative;
        margin-bottom: 40px;
    }
}

.#{$footer}{
    position: fixed;
    bottom: 0;
    z-index:3;
}

.#{$data}{
 margin: 0 40px;
}


@media (max-width:  800px){
    .#{$root}{
       margin-top: 20px;
    }
}
