@import '../../theme/common.scss';

$componentName: "up-arrow";
$root: #{$classPrefix}#{$componentName}-root;


.#{$root}{
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 10;
    right: 30px;
    bottom: 30px;
    cursor: pointer;
}



:export {
    root: $root;
}