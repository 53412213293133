@import '../../theme/common.scss';

$componentName: "career";
$root: #{$classPrefix}#{$componentName}-root;
$line: #{$classPrefix}#{$componentName}-line;
$careerStages: #{$classPrefix}#{$componentName}-stages;
$date: #{$classPrefix}#{$componentName}-date;
$title: #{$classPrefix}#{$componentName}-title;
$place: #{$classPrefix}#{$componentName}-place;
$description: #{$classPrefix}#{$componentName}-description;
$careerStage: #{$classPrefix}#{$componentName}-stage;
$linePart: #{$classPrefix}#{$componentName}-line-part;
$circle: #{$classPrefix}#{$componentName}-circle;

.#{$root}{
    display: flex;
    font-size: $font-size-xsmall;

    
    .#{$careerStage}{
        margin-bottom: 30px;
    }

    .#{$date}{
        font-size: $font-size-xxxsmall;
        color: $color-dark2;
        font-weight: $font-weight-medium;
    }

    .#{$place}{
        font-size: $font-size-xxxsmall;
        color: $color-dark2;
        h4 {
            display: inline;
        }
    }

    .#{$description}{
        font-size: $font-size-xxsmall;
        color: $color-dark2;
        margin-top: 20px;
    }

    .#{$line}{
        border-left: 2px solid $color-dark2;
    }

    .#{$linePart}{
        padding-right: 20px;
    }

    .#{$title}{
        position: relative;
    }

    .#{$circle}{
        border-radius: 100%;
        background: $color-light;
        width: 20px;
        height: 20px;
        position: absolute;
        left: -33px;
        top: 4px;
        z-index: 2;
        border: 2px solid $color-dark2;
    }
} /* eof root */
