/*  TYPOGRAPHY  */

/* Define base font sizes */

$font-size-xxlarge: 68pt;
$font-size-xlarge: 48pt;
$font-size-large: 32px;
$font-size-normal: 28px;
$font-size-small: 24px;
$font-size-xsmall: 20px;
$font-size-xxsmall: 18px;
$font-size-xxxsmall: 16px;
$font-size-xxxxsmall: 14px;
$font-size-xxxxxsmall: 12px;

/* Define base font weights */

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400; // normal
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700; // bold
$font-weight-exrta-bold: 800;
$font-weight-ultra-bold: 900;