@import '../../../theme/common.scss';

$componentName: "project";
$dataRoot: #{$classPrefix}#{$componentName}-data-root;
$image: #{$classPrefix}#{$componentName}-image;
$media: #{$classPrefix}#{$componentName}-media;
$downloads: #{$classPrefix}#{$componentName}-downloads;
$descAndDownload: #{$classPrefix}#{$componentName}-desc-and-download;
$description: #{$classPrefix}#{$componentName}-description;
$otherDownloads: #{$classPrefix}#{$componentName}-other-downloads;
$root: #{$classPrefix}#{$componentName}-root;
$header: #{$classPrefix}#{$componentName}-header;
$name: #{$classPrefix}#{$componentName}-name;
$video: #{$classPrefix}#{$componentName}-video;
$carousel: #{$classPrefix}#{$componentName}-carousel;

.#{$header}{
    display: flex;
    flex-direction: row;
    margin: 140px 40px 40px 40px;
    padding-bottom: 20px;
    border-bottom: 2px solid $color-dark2;

    .#{$name}{
        margin-left: 20px;
        line-height: 26px;
        display: flex;
        align-items: center;
    }
    .#{$image}{
        width: 80px;
        height: 80px;
    }
    
}



.#{$dataRoot}{
    margin: 0 40px 60px 40px;
    .#{$media}{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .#{$video}{
            width: 50%;
            
            iframe {
                width: 100%;
                max-width: 100%;
                aspect-ratio: 16 / 9;
            }

        }

        .#{$carousel}{
            margin-right: 20px;
            width: calc(100% - 560px);
        }
    }

    img{
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        margin-bottom: 20px;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .#{$descAndDownload}{
        display: flex;
        .#{$description}{
            flex: 4;
            padding-right: 20px;
        }
        .#{$downloads}{
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-width: 300px;
        }
    }
    
} /* eof root */

@media (max-width:  850px){
    .#{$dataRoot}{
        .#{$media}{
            flex-direction: column;

            iframe {
                max-width: 100%;
            }

            .#{$video}{
                width: 100%;
            }
    
            .#{$carousel}{
                width: 100%;
            }
        }

        
        .#{$image}{
            width: 100px;
            height: 100px;
        }

        .#{$descAndDownload}{
            flex-direction: column;
        }

        .#{$downloads}{
            margin-top: 20px;
        }
    }

}