@import '../../theme/common.scss';

$componentName: "rating";
$root: #{$classPrefix}#{$componentName}-root;
$emptyStar: #{$classPrefix}#{$componentName}-empty-star;
$star: #{$classPrefix}#{$componentName}-star;


.#{$root}{
    display: flex;

    .#{$emptyStar}{
        background-image: url(../../assets/leber-design.png);
        background-repeat: no-repeat;
        background-size: 150px 150px;
        width: 20px;
        height: 20px;
        background-position: -34px -34px;
    }
    .#{$star}{
        background-image: url(../../assets/leber-design.png);
        background-repeat: no-repeat;
        background-size: 150px 150px;
        width: 20px;
        height: 20px;
        background-position: -10px -34px;
    }
} /* eof section */