@import '../../theme/common.scss';

$componentName: "header";
$root: #{$classPrefix}#{$componentName}-root;
$logo: #{$classPrefix}#{$componentName}-logo;
$logoLeber: #{$classPrefix}#{$componentName}-logo-leber-software;


.#{$root}{
    background: $color-dark;
    color: $color-light;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    position: fixed;
    width: calc(100% - 80px);
    top: 0;
    left: 0;
    z-index: 10;

    .#{$logoLeber}{
        width: 55px;
        height: 55px;
        background:  url(../../assets/leber-design.png) no-repeat;
        background-size: 370px 370px;
        background-position: -134px -7px;
    }

    .#{$logo}{
        display: block;
        text-indent: -9999px;
        transition: opacity .2s;

        &:hover {
            opacity: .5;
        }
    }
} // eof header root


@media (max-width:  800px){
    .#{$root}{
        width: calc(100% - 40px);
        padding: 0 20px;
    }
}
