@import '../../theme/common.scss';

$componentName: "button";
$root:  #{$classPrefix}#{$componentName}-root;

.#{$root}{
    background: $color-light3;
    cursor: pointer;
    padding: 8px;
    font-weight: $font-weight-semi-bold;
    color: $color-dark2;
    text-align: center;
} /* eof root */