@import '../../theme/common.scss';

$componentName: "contact";
$root: #{$classPrefix}#{$componentName}-root;
$dataRoot: #{$classPrefix}#{$componentName}-data-root;
$footer: #{$classPrefix}#{$componentName}-footer;

.#{$root}{
    .#{$sectionTitleContainer}{
        position: relative;
        width: 100%;
        text-align: center;
        margin-top: 120px;
    }
    .#{$sectionTitle}{
        font-size: $font-size-large;
        color: $color-dark2;
        background-color: $color-light3;
        font-style: italic;
        padding: 8px;
        text-align: center;
        white-space: pre-wrap;
        display: inline-block;
        zoom: 1;
        max-width: 100%;
        position: relative;
        margin-bottom: 40px;
    }
}

.#{$footer}{
    position: fixed;
    bottom: 0;
    z-index:3;
}

.#{$dataRoot}{

    margin: 0 40px 400px 40px;
    display: flex;
    justify-content: center;
    a {
        color: $color-dark2;
    }
} // eof intro 

@media (max-width:  800px){
    .#{$root}{
       margin-top: 20px;
    }
}
