@import '../../theme/common.scss';

$componentName: "social-media-section";
$logo: #{$classPrefix}#{$componentName}-logo;
$root: #{$classPrefix}#{$componentName}-root;
$socialMediaLogo: #{$classPrefix}#{$componentName}-social-media-logo;
$logoLinkedIn: #{$classPrefix}#{$componentName}-logo-linked-in;
$logoFacebook: #{$classPrefix}#{$componentName}-logo-facebook;
$logoX: #{$classPrefix}#{$componentName}-logo-x;


.#{$root}{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .#{$logo}{
        display: block;
        text-indent: -9999px;
        transition: opacity .2s;

        &:hover {
            opacity: .5;
        }
    }

    .#{$socialMediaLogo}{
        width: 25px;
        height: 25px;
        background-image: url(../../assets/leber-design.png);
        background-size: 250px 250px;
    }

    .#{$logoLinkedIn}{
        background-position: -15px -10px;
    }

    .#{$logoFacebook}{
        background-position: -60px -10px;
        width: 15px;
    }

    .#{$logoX}{
        width: 25px;
        height: 25px;
        background-position: -106px -5.5px;
        background-size: 200px 200px;
        position: relative;
        top:2px;
    }
} // eof header root


@media (max-width:  800px){
    .#{$root}{
        position: fixed;
        bottom: 40px;
        z-index: 13;
        left: calc(50% - 100px);
        display: none;
    
        &.menu-opened {
            display: flex;
        }
    }
}
