@import '../../theme/common.scss';

$componentName: "menu";
$root: #{$classPrefix}#{$componentName}-root;
$nav: #{$classPrefix}#{$componentName}-nav;
$navItem: #{$classPrefix}#{$componentName}-nav-item;
$active: #{$classPrefix}#{$componentName}-active;
$hamburgerIcon: #{$classPrefix}#{$componentName}-hamburger-icon;

.#{$root}{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.#{$nav}{
    list-style-type: none;
    display: flex;
    gap: 20px;
    width: calc(100% - 145px);
    justify-content: center;

    .#{$navItem} a{
        color: $color-light;
        text-decoration: none;
    }

    .#{$navItem} a:hover{
        color: $color-light-hover;
    }

    .#{$active} a{
        color: $color-light;
        font-weight: $font-weight-bold;
        text-decoration: none;
    }
    

} // eof nav root

.#{$hamburgerIcon}{
    width: 32px;
    height: 32px;
    path {
        fill: white;
    }
    position: fixed;
    right: 20px;
    top: 25px;
    z-index: 12;
    display: none;
}

@media (max-width:  800px){

    .#{$root}{
        width: calc(100% - 55px);
    }
    
    .#{$nav}{
        display: none;
        &.menu-opened{
            width: 100%;
            position: absolute;
            z-index: 12;
            height: 100vh;
            left: 0;
            background: black;
            top: 79px;
            margin-top: 0;
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding-top: 40px;
            justify-content: flex-start;
        }
    }

    .#{$hamburgerIcon}{
        display: block;
    }

    
}

