@import '../../theme/common.scss';

$componentName: "awards";
$root: #{$classPrefix}#{$componentName}-root;
$award: #{$classPrefix}#{$componentName}-award;
$awardContent: #{$classPrefix}#{$componentName}-award-content;
$awardImage: #{$classPrefix}#{$componentName}-award-image;

.#{$root}{
    color: $color-dark2;
    font-size: $font-size-xsmall;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 20px;

    ul li {
        margin-bottom: 20px;
    }

    a {
        color: $color-dark2;
    }

    .#{$award}{
        .#{$awardContent}{
           display: block;

           a {
               float: left;
           }

           & > div {
               margin-bottom: 10px;
           }
        }
        .#{$awardImage}{
            width: 160px;
            height: 100px;
            margin: 0 20px 0 0;
        }
    }
} /* eof root */


@media (max-width:  850px){
    .#{$root}{
        font-size: $font-size-xxsmall;
        ul {
            padding: 8px;
        }
        ul li {
            list-style-type: square;
        }

        .#{$award}{
            .#{$awardContent}{
                display: block;

                a {
                    float: left;
                }

                & > div {
                    margin-bottom: 10px;
                }
            }
        }
    }
}