@import '../../theme/common.scss';

$componentName: "main";
$intro: #{$classPrefix}#{$componentName}-intro;
$introSubtitle: #{$classPrefix}#{$componentName}-intro-subtitle;
$introName: #{$classPrefix}#{$componentName}-intro-name;
$technologyStack: #{$classPrefix}#{$componentName}-technology;


.#{$intro}{
    background-image: url(../../assets/intro-bg-laszlo-leber-colored.png),  url(../../assets/bg3.jpg);
    background-position: right 33px, left top;
    background-repeat: no-repeat, repeat;
    background-size: auto 100%, 100% 100%;
    background-attachment: scroll, fixed;
    height: calc(100vh - 80px);
    background-color: #242424;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
    color: $color-dark2;
    margin-top: 80px;

    .#{$introSubtitle}{
        font-size: calc(1 * (1.5vh + 1.1vw));
        font-weight: 500;
        font-style: italic;
    }

    .#{$introName}{
        font-size: calc(3 * (1.5vh + 1.1vw));
        font-weight: 500;   
    }
} // eof intro 

.#{$section}:nth-child(even){
    background-color: $color-light2;

    .#{$sectionTitle}{
        background-color: $color-light;
    }
}

.#{$section}{
    .#{$technologyStack}{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        font-size: $font-size-small;

        & > div {
            width: 50%;
            text-align: center;
            
            h3 {
                font-size: $font-size-small;
                margin-bottom: 20px;
                display: inline-block;
                border-bottom: 1px solid $color-dark2;
                padding: 0 20px;
            }

            ul {
                list-style: circle;
                li {
                    text-align: left;
                    font-size: $font-size-xsmall;
                    margin: 20px 0;
                }
            }
        }
    }
} /* eof section */


@media (max-width:  850px){
    .#{$intro}{
        background-position: center bottom, left top;
        background-repeat: no-repeat, repeat;
        background-size: auto 66%, 100% 100%;
        background-attachment: scroll, scroll;
        height: calc(100vh - 220px);
        justify-content: flex-start;
        padding: 140px 0 0 0;
        margin: 0;
        align-items: center;
        padding-left: 0;
    }

    .#{$section}{
        .#{$technologyStack}{
            flex-direction: column;
            & > div {
                width: 100%;
            }
        }
    }

}

@media (max-width:  1100px) and (min-width: 850px){
    .#{$intro}{
        padding-left: 5%;

        .#{$introName}{
            font-size: calc(2 * (1.5vh + 1.1vw));
            font-weight: 500;   
        }
    }
}